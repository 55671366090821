import { Component, Injectable, AfterViewInit } from '@angular/core';
import { ScrollSpyService } from 'ngx-scrollspy';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements AfterViewInit {
  sections = {};
  showSub = false;
  currentUrl = '/';
  constructor(private scrollSpyService: ScrollSpyService,
    private router: Router
  ) {

    this.router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        this.currentUrl = res.url;
        this.showSub = false;
      }
    })
  }

  isActive(url) {
    let result = false;
    if (this.currentUrl == url) {
      result = true;
    }
    if (url == '/latest-news') {
      if (this.currentUrl.indexOf("/case/") >= 0 || this.currentUrl == '/charity' || this.currentUrl == '/case') {
        result = true;
      }
    }
    return result;
  }

  goTo(url) {
    this.router.navigateByUrl(url);
    this.toggleMenu();
  }

  ngOnInit() {
    window.onscroll = function () {
      var navbar1 = document.getElementById("navbar1");
      if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
        navbar1.style.backgroundColor = "#272a33";
        navbar1.style.padding = "10px";
      }
      else {
        navbar1.style.backgroundColor = "";
        navbar1.style.padding = "20px";
      }
    };
  }

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
    document.getElementById('menu_button').classList.toggle('collapsed');
  }

  toggleSub() {
    this.showSub = !this.showSub;
    var navbar1 = document.getElementById("navbar1");
    if (this.showSub) {
      navbar1.style.backgroundColor = "#272a33";
      navbar1.style.padding = "10px";

    } else {
      if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
        navbar1.style.backgroundColor = "#272a33";
        navbar1.style.padding = "10px";
      } else {
        navbar1.style.backgroundColor = "";
        navbar1.style.padding = "20px";
      }
    }
  }

  ngAfterViewInit() {
  }
}



